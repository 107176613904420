export default class TestataOrdineStorico{

    NumeroOrdine;
    CodiceCliente;
    PrezzoTotale;
    DataOrdine;
    IndirizzoSpedizione;
    IndirizzoFatturazione;

    constructor(){}

}