export default class RigaOrdineStorico{

    NumeroOrdine;
    IdRiga;
    CodiceArticolo;
    DescrizioneArticolo;
    PrezzoListino;
    PrezzoNetto;
    TotaleRiga;
    Quantita;
    Evaso;
    DataConsegna;
    Disponibilita;
    Prezzo;
    DescrizioneTaglia;
    DescrizioneColore;
    UrlImmagine;
    Catalogo;
    constructor(){}
}