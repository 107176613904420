<template>
    <section>
        <div class="Container">
            <div class="Box">
                <div class="FormRow" style="align-items: center !important;">
                    <a v-if="this.isKit" :href="$router.resolve({ name: 'DettaglioProdotto', params: { ProductId: this.lastNavigation, CodiceSocieta: this.CodiceSocieta } }).href"><img src="images/BackButtonCircle.png" style="width:35px;"/></a>
                    &nbsp;&nbsp;
                    <a href="#/" style="text-decoration:none; font-size: 15px;">Home</a>
                </div>
                <br/>
            </div>
        </div>
        <div class="Container">
            <div class="Box">
                <div class="Binder Big ItemMainBox">
                    <div class="ItemMainImage" v-if="Prodotto.CodiceArticolo">
                        <i><span>
                            <product-image :PathArticolo="Prodotto?.PathImmagineFull"></product-image>
                            </span>
                        </i>
                    </div>
                    <div class="ItemMainData">
                        <h2>{{Prodotto.DescrizioneGruppoStatistico4}}</h2>
                        <h1>Codice : {{Prodotto.CodiceArticolo}}</h1>
                        
                        <h3>DESCRIZIONE:</h3>
                        <div class="TxtEditor">
                            <h3>{{Prodotto.DescrizioneArticolo}}</h3>
                        </div>
                        <div class="DetailView" v-if="Prodotto.Kits && Prodotto.Kits.length > 0">
                            <a href="javascript:void(0);" @click="this.scrollMeTo('DettaglioKit')">Composizione del kit</a>
                        </div>
                        
                        <div class="SizeSelectBox" v-if="Prodotto.IsAVarianti">
                            <h3>Scegli la taglia</h3>
                            <router-link :to="{ name:'ZoomFile', params: {IdImg: this.typeImg, ProductId:this.$route.params.ProductId, CodiceSocieta: this.$route.params.CodiceSocieta} }">Guida alle taglie</router-link>
                            <ul>
                                <li v-for="taglia in this.Prodotto.Varianti" :key="taglia.CodiceArticolo + taglia.CodiceVariante"  @click="setSize(taglia.CodiceVariante)">
                                    <a class="MyClass" href="javascript:void(0);" :class="(taglia.CodiceVariante === this.VarianteSelezionata ? 'SelectedItem' : '')">{{taglia.CodiceVariante}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="PriceBox" v-if="!this.isB2B">
                            <span v-if="this.PrezzoB2C > 0">
                                {{ toEUR( this.PrezzoB2C ) }}
                            </span>
                            <span v-else style="font-size: 17px;">
                                Articolo temporaneamente non vendibile singolarmente
                            </span>
                        </div>
                        <div class="PriceBox" v-if="this.isB2B">
                            <span v-if="this.PrezzoB2B > 0">
                                {{ toEUR( this.PrezzoB2B ) }}
                            </span>
                            <span v-else style="font-size: 17px;">
                                Articolo temporaneamente non vendibile singolarmente
                            </span>
                        </div>
                        <add-to-cart-btn 
                            :Prodotto="Prodotto" 
                            :Variante="VarianteSelezionata" 
                            :CodiceSocieta="this.CodiceSocieta"
                            :SubmitFormBtn="this.disableBtnAggiungi"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section ref="DettaglioKit" class="Relative" v-if="Prodotto.Kits && Prodotto.Kits.length > 0">
        <div class="Container" >
            <div class="Box">
                <div class="Bookmark"><span>IL KIT {{Prodotto.CodiceArticolo}} E' COMPOSTO DA:</span></div>
            </div>
            <div class="Box">
                <div class="Binder KitElements">
                    <product-card v-for="Elemento in Prodotto.Kits" :key="Elemento.CodiceArticolo" :Prodotto="Elemento" :Prezzo="Prezzo" :Template="'Element'" :CodiceSocieta ="this.CodiceSocieta"></product-card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Modalcart from "@/components/modals/Modalcart.vue";
import ProductsService from "@/services/products.service.js";
import { modelMixin, risorseMixin, valutaMixin, userMixin} from "@/mixin.js";
import ProductCard from "@/components/ProductCard.vue";
import ProductImage from "@/components/ProductImage.vue";
import AddToCartBtn from '@/components/AddToCartBtn.vue';
import Loader from '@/components/Loader.vue';

const basePathUrl = process.env.VUE_APP_BASEMEDIA_URL;
export default {
    name: "DettaglioProdotto",
    data() {
        return {
            TipoProdotto:"",
            Prezzo: "",
            Prodotto: [],
            VarianteSelezionata:"",
            SelectedQty: 1,
            showModal: false,
            PrezzoB2C:0.00,
            PrezzoB2B:0.00,
            CodiceSocieta: null,
            disableBtnAggiungi: true,
            lastElem: null,
            isKit: false,
            typeImg: "n"
        };
    },
    props:{
        ProductId: String
    },
    methods: {
        getData: function (ProductId) {
            this.IsReady = false;
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            this.CodiceSocieta = this.$route.params.CodiceSocieta;
            this.$store.commit('setLoader', true);
            ProductsService.getArticolo(token, shop, "KIT", ProductId, this.CodiceSocieta).then(data => {
                this.$store.commit('setLoader', false);
                if (!data) {
                    this.$router.push("/");
                }
                else {
                    this.Prodotto = data;
                    let v = this.$store.getters.getShop;
                    this.typeImg = this.Prodotto?.PathImmagineGuidaTaglie;
                    this.PrezzoB2B = data.Prezzo;
                    if(v == 'b2b'){
                        if(data.Prezzo == 0){
                            data.Prezzo = 0.00;
                            this.disableBtnAggiungi = true;
                        }else{
                            this.disableBtnAggiungi = false;
                        }
                    }
                }
            });
        },
        getPrice: function (ProductId) {
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            let CodS = this.$route.params.CodiceSocieta;
            let CodD = shop == 'b2b' ? 1 : 20;
            this.$store.commit('setLoader', true);
            ProductsService.getPrezzoArticolo(token, shop, CodS, ProductId, CodD ).then(data => {
                this.$store.commit('setLoader', false);
                if ( data ) {
                    if(data.Prezzo == 0){
                        this.PrezzoB2C = 0;
                        this.disableBtnAggiungi = true;
                    }else{
                        this.PrezzoB2C = data.Prezzo;
                        this.disableBtnAggiungi = false;
                    }
                }else{
                    this.PrezzoB2C = 0;
                    this.disableBtnAggiungi = true;
                }
            });
        },
        setSize: function (size) {
            this.VarianteSelezionata = size;
        },
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        }
    },
    mixins: [modelMixin, risorseMixin, valutaMixin, userMixin],
    computed: {
        checkSize() {
            return (size) => {
            let variantiKit = this.Prodotto.Varianti;
            let exists = false;
            variantiKit.forEach(
                function(element, index) {
                if ( element.CodiceVariante == size ) exists = true;
                });
            if (exists) return true;
            };
        },
        lastNavigation: function(){
            if(this.lastElem == null){
                return localStorage.getItem('lastKit');
            }else{
                return this.lastElem;
            }
        },
        PathImgTaglie(){
            return basePathUrl + this.Prodotto?.PathImmagineGuidaTaglie;
        },
    },
	watch: { 
		'$route.params.ProductId': {
			handler: function(newVal,oldVal) {
                if(this.$route.params.ProductId){
                    this.getData(this.$route.params.ProductId);
                    this.getPrice(this.$route.params.ProductId);
                    if(newVal != null && oldVal != null){
                        let oldTmp = oldVal.split('.');
                        if(oldTmp[0].toLowerCase() == 'k'){
                            this.lastElem = oldVal;
                            localStorage.setItem('lastKit', oldVal);
                            this.isKit = true;
                        }else{
                            this.isKit = false;
                        }
                    }
                }
			},
			deep: true,
			immediate: true
		}
	},
    created: function () {
        this.$store.commit('setLoader', true);
        this.CodiceSocieta = this.$route.params.CodiceSocieta;
        this.getPrice(this.$route.params.ProductId);
    },
    components: { 
        ProductCard,
        Modalcart,
        AddToCartBtn,
        ProductImage,
        Loader
    }
}
</script>

<style>
.MyClass{
    border-radius: 15% !important;
}
</style>
