<template>
    <Loader v-if="isLoading"/>
    <div class="AddToCartBox">
    <div class="QtyLabel">Qtà.</div>
        <div class="QtySelectorBox">
            <div class="QtySelectorReset"><a href="#"><img src="images/ResetIcon.png" srcset="images/ResetIconRetina.png 2x" alt="" /></a></div>
            <div class="QtySelector">
                <div class="QtySelectorButton" @click="this.lessQty()">-</div>
                    <input type="text" class="CurrentQty" v-model="SelectedQty"/>
                <div class="QtySelectorButton" @click="this.plusQty()">+</div>
            </div>
            <div class="QtySelectorRefresh" style="display:none"><img src="images/RefreshIcon.png" srcset="images/RefreshIconRetina.png 2x" alt="" /></div>
        </div>
        <div v-if="!this.SubmitFormBtn" class="Button" :class="{Disabled :(this.SubmitFormBtn == false )}">
            <a href="javascript:void(0)" @click="this.addToCart()"><span>Aggiungi al carrello</span>
                <img src="images/GoalIcon.png" srcset="images/GoalIconRetina.png 2x" alt="" />
            </a>
        </div>   
        <div v-else class="Button" >
            <a class="Disabled" href="javascript:void(0)"><span>Aggiungi al carrello</span>
                <img src="images/GoalIcon.png" srcset="images/GoalIconRetina.png 2x" alt="" />
            </a>
        </div>   
    </div>
    <Modalcart v-model="this.showModal"/>
</template>

<script>
import {priceMixin, valutaMixin, risorseMixin} from "@/mixin.js";
import ModalOrder from "@/components/modals/ModalOrder.vue";
import Modalcart from "@/components/modals/Modalcart.vue";
import Loader from '@/components/Loader.vue'


export default {
    name: "AddToCart",
    data() {
        return {
            SelectedQty: 1,
            showModal: false,
            isLoading: false
        };
    },
    mixins: [priceMixin, valutaMixin, risorseMixin],   
    components:{
        ModalOrder,
        Modalcart,        
        Loader
    },

    props: [
        'Prodotto',
        'Variante',
        'CodiceSocieta',
        'SubmitFormBtn'
    ],
    watch:{
        SelectedQty: function(newValue,oldValue){
            if(!isNaN(newValue)){
                if(newValue != ""){
                    this.SelectedQty = newValue;
                }
            }else{
                this.SelectedQty = oldValue;
            }
        }
    },
    methods:{
        plusQty: function () {
            this.SelectedQty++;
        },

        lessQty: function () {
            if( this.SelectedQty > 1 ){
                this.SelectedQty--;
            }
        },
        addToCart: function(){
            if ( this.Prodotto.IsAVarianti && !this.Variante ) {
                this.$toast.error("Seleziona la taglia");
                return;
            }
            this.isLoading = true;
            this.$store.dispatch({
                type: 'setCarrello',
                shop: this.$store.getters.getShop,
                token: this.$store.getters.getToken,
                product: this.Prodotto,
                qty: this.SelectedQty,
                v: this.Variante,
                CodiceSocieta: this.CodiceSocieta
            })
            .then(
                (data) => {                
                    this.showModal = true;
                    this.isLoading = false;
                }
            );
        }
    }
}
</script>

<style>
.Disabled{
	background-color: grey !important;
	background-image: linear-gradient(grey,grey) !important;
}
</style>