<template>
    <div class="AddShopBox">
    <div class="AddShopPanel">
        <div class="Frame">
            <div class="AddShopPanelTable">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                        <tr>
                            <td>{{ this.risorse["TotaleProdotti"] }}</td>                           
                            <td>{{this.simboloValuta}} {{this.formatNumber((totalProducts), 2, ',', '.')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="Button CustomColor Iconed AddShop"><a href="javascript:void(0)" @click="this.addToCart()">{{ this.risorse["AggiungiCarrello"] }}</a></div>
        </div>
    </div>
</div>
<Modalcart v-model="this.showModal" :response="this.responseCart" ></Modalcart>
</template>

<script>

import Modalcart from "@/components/modals/Modalcart.vue";
import CartService from '@/services/cart.service.js';
import AddCartResponse from '@/models/AddCartResponse.model.js';
import {priceMixin, valutaMixin, risorseMixin} from "@/mixin.js";

export default {
    name: "ShopBox",   
    data(){
        return {
            showModal: false,
            responseCart: 1           
        }
    },
    computed:{
        totalProducts: function(){
            return this.$store.getters.getPrezzoTotaleTmp;
        }
    },
    components:{
        Modalcart
    },
    mixins: [priceMixin, valutaMixin, risorseMixin],
    methods:{        
        addToCart: function(){
            
            if(!this.$store.getters.getCarrelloTmp || this.$store.getters.getCarrelloTmp.Righe.length == 0){
                this.showModal = true; 
                this.responseCart = 2;
                return;
            }

            this.$store.dispatch({
                type: 'setCarrello',
                token: this.$store.getters.getToken  
            }).then(
                (data) => {
                    if(data){
                      
                        CartService.getCartByUsername(this.$store.getters.getToken).then(
                            (data) => {                    
                                if(data){
                                    let responseCart = new AddCartResponse();
                                    Object.assign(responseCart, data);
                                    if(responseCart.Status == 1){  
                                      
                                        this.$store.commit('resetCarrelloTmp');
                                        this.$store.commit('updateCarrello', responseCart.Carrello);
                                    }
                                }
                            }
                        );  
                        this.showModal = true; 
                    }
                    else{
                        this.showModal = true; 
                        this.responseCart = -1;
                    }
                }
            );
        }
    }
}
</script>
