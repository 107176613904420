<template>

    <vue-final-modal  v-model="showModal" classes="modal-container" content-class="modal-content mymodal" name="modalOrder"  @before-close="this.closeModal()" @before-open="this.getRigheOrdine()">
        <button class="modal__close" @click="this.$vfm.hide('modalOrder') ">
            <mdi-close/>
        </button>  
    <div class="FancyHolder">
        
		<div class="Box">
            <div class="WhiteSpace Big">&nbsp;</div>
            <div class="Frame">
                <div class="OrderDetailTableIndex"><h2><span>{{ this.risorse["Ordine"] }}</span> <strong>{{order?.testata?.NumeroOrdine}}</strong> <i>{{ order?.testata?.DataOrdine}}</i></h2></div>
            </div>
            <div class="WhiteSpace Big">&nbsp;</div>
            <div class="Frame">
                <div class="OrderDetailTable Table">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr class="TableHead">
                                <th scope="col" class="ODTProductName">{{ this.risorse["Nome"] }}</th> 
                                <th scope="col" class="ODTStock">{{ this.risorse["TabVarStock"] }}</th> 
                                <th scope="col" class="ODTProductColor">{{ this.risorse["TabVarColore"] }}</th> 
                                <th scope="col" class="ODTProductSize">{{ this.risorse["TabVarTaglia"] }}</th> 
                                <th scope="col" class="ODTProductCode">{{ this.risorse["TabVarCodice"] }}</th> 
                                <th scope="col" class="ODTUnitPrice">{{ this.risorse["TabVarPrezzoUn"] }}</th> 
                                <th scope="col" class="ODTQuantity">{{ this.risorse["TabVarQty"] }}</th> 
                                <th scope="col" class="ODTSubtotal">{{ this.risorse["TabVarSub"] }}</th> 
                            </tr>
                            <!-- Inizio repeater -->                            
                            <!--<RigaOrdineStorico  v-for="variant in this.order.righe" :key="variant.IdRiga" :variant="variant">
                            </RigaOrdineStorico>-->
                            <!-- Fine repeater -->                           
                        </tbody>
                    </table>
                </div>               
            </div>           
            <ShopBox></ShopBox>
            <div class="WhiteSpace Big">&nbsp;</div>         
        </div>
    </div>    
    </vue-final-modal>

</template>    

<script>

import MdiClose from "@/components/modals/MdiClose.vue";
import ShopBox from "@/components/ShopBox.vue";
import OrderHistoryService from "@/services/orderHistory.service.js";
import RigaOrdineStorico from "@/components/RigaOrdineStorico.vue";
import {valutaMixin, risorseMixin} from "@/mixin.js";

export default { 
    name: "ModalOrder",
    props:{
        //showModal: Boolean,
        order: Object      
    },
    data(){
        return {
            righeOrdine: [],
            totaleProdottiCarrello: 0,
            showModal:null
        }
    },
    components:{
        MdiClose,
        ShopBox,
        RigaOrdineStorico
    },  
    mixins: [valutaMixin, risorseMixin],   
    methods:{
        closeModal: function(){           
            this.$store.commit('resetCarrelloTmp');          
        },     
        getRigheOrdine: function(){
            /*
            OrderHistoryService.GetRigheByOrdine(this.$store.getters.getToken, this.order.NumeroOrdine).then(

                (data) => {                               
                    if(!data){
                        this.$router.push("/");
                    }
                    else{    
                        data.forEach(element => {
                            if(element.Disponibilita > 0 && element.Catalogo){                                
                                this.$store.commit('updateRow', {                                
                                CodiceArticolo: element.CodiceArticolo, 
                                Quantita: element.Quantita, 
                                PrezzoNetto: element.PrezzoNetto, 
                                PrezzoListino: element.PrezzoListino,
                                Sconto1: 0,
                                Sconto2: 0,
                                Sconto3: 0,
                                Sconto4: 0,
                                Sconto5: 0,
                                ValoreSconto1: 0,
                                ValoreSconto2: 0,
                                ValoreSconto3: 0,
                                ValoreSconto4: 0,
                                ValoreSconto5: 0,
                                Catalogo: element.Catalogo,
                                DescrizioneArticolo: element.DescrizioneArticolo,
                                DescrizioneTaglia: element.DescrizioneTaglia,
                                DescrizioneColore: element.DescrizioneColore,    
                                UrlImmagine: element.UrlImmagine,
                                Valuta: this.codiceValuta                
                                });
                            }
                        });                       
                        this.totaleProdottiCarrello = this.$store.getters.getPrezzoTotale;                                          
                        this.righeOrdine = data;                        
                    }                  
                }
            ).catch(
                (err) => {console.log(err)}
            );*/
        }
    }
}
</script>
<style>
.priceCont{
    cursor:pointer;
}

.mymodal{
    width: 70%;
    height: 60%;
    overflow-y: scroll;
    background: #F3F5F8;   
} 

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem; 
}

</style>