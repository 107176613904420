import axios from "axios";
import TestataOrdineStorico from "@/models/TestataOrdineStorico.model.js";
import RigaOrdineStorico from "@/models/RigaOrdineStorico.model.js";

const API_URL = process.env.VUE_APP_API_URL;

class OrderHistoryService{

    async GetTestataOrdiniByCliente(token, codiceCliente, dayDiff){
        return axios({
            method: 'get',
            url: API_URL + 'storicoordini/GetTestataOrdiniByCliente',
            params: {
                codiceCliente: codiceCliente,
                dayDiff: dayDiff
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.map(o => {                       
                        return Object.assign(new TestataOrdineStorico(), o);
                    });
                }               
            }
        )
        .catch(error => {return error;}); 
    }

    async GetRigheByOrdine(token, codiceOrdine){
        return axios({
            method: 'get',
            url: API_URL + 'storicoordini/GetRigheByOrdine',
            params: {
                codiceOrdine: codiceOrdine              
            }, 
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {                                         
                if(data.status == 401){
                    return null;
                }
                else{  
                    let result =  data.data.map(r => {                       
                        return Object.assign(new RigaOrdineStorico(), r);
                    });                  
                    return result;
                }               
            }
        )
        .catch(error => {return error;}); 
    }
}

export default new OrderHistoryService();
