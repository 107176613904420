export default class AddCartResponse{
    StatusCode;
    Message;
    Data;

    constructor(StatusCode, Message, Data){
        this.StatusCode = StatusCode;       
        this.Message = Message;
        this.Data = Data;
    }
}