<template>
    <!-- Template per Carosello -->
    <div class="ItemThumbBox" v-if="Template == 'Element'">
        <a :href="$router.resolve({ name: 'DettaglioProdotto', params: { ProductId: Prodotto.CodiceArticolo, CodiceSocieta: this.CodiceSocieta } }).href">
            <div class="ItemThumbImage">
                <span>
                    <product-image 
                        :PathArticolo="Prodotto.PathImmagineThumb" 
                    />
                </span>
            </div>
            <div class="ItemThumbText">
                <span> {{ Prodotto.DescrizioneArticolo }}</span>
                <i>N. {{ Prodotto.Quantita }}</i>
            </div>
        </a>
    </div>

    <div class="ClassicIkarusItem" v-if="Template == 'Carousel'">
        <div class="ItemThumbBox">
        <a :href="$router.resolve({ name: 'DettaglioProdotto', params: { ProductId: Prodotto.CodiceArticolo, CodiceSocieta: this.CodiceSocieta } }).href">
                <div class="ItemThumbImage">
                    <span>
                        <product-image 
                            :PathArticolo="Prodotto?.PathImmagineThumb"
                        />
                    </span>
                </div>
                <div class="ItemThumbText">
                    <p>{{ Prodotto.CodiceArticolo }} | {{ Prodotto.CodiceFamiglia }}</p>
                </div>
                <div class="ItemThumbText">
                    <span> {{ Prodotto.DescrizioneArticolo }}</span>
                </div>
        </a>
        </div>
    </div>

    <div class="ItemThumbBox" v-if="Template == 'FeaturedProduct'">
        <a :href="$router.resolve({ name: 'DettaglioProdotto', params: { ProductId: Prodotto.CodiceArticolo, CodiceSocieta: this.CodiceSocieta } }).href">
            <div class="ItemThumbImage">
                <span>
                    <product-image 
                        :PathArticolo="Prodotto?.PathImmagineThumb"
                    />
                </span>
            </div>
            <div class="ItemThumbText">
                <p>{{ Prodotto.CodiceArticolo }} | {{ Prodotto.CodiceFamiglia }}</p>
                
            </div>
            <div class="ItemThumbText">
                <span> {{ Prodotto.DescrizioneArticolo }}</span>
                <i> {{ this.toEUR (Prezzo) }}</i>
            </div>
        </a>
    </div>

</template>

<script>
import ProductImage from "@/components/ProductImage.vue";
import { modelMixin, risorseMixin, userMixin, valutaMixin } from "@/mixin.js";
export default {
    name: "ProductCard",
    props: ['Prodotto', 'Template', 'Prezzo','CodiceSocieta'],
    mixins: [modelMixin, risorseMixin, userMixin, valutaMixin],
    components: { ProductImage }
}
</script>