<template>
    <tr class="TableRow" :class="{ 'InStock': this.inStockStorico(variant), 'OutOfStock': this.outOfStockStorico(variant), 'FewPieces': this.fewPiecesStorico(variant) }">
        <td class="ODTProductName">
            <small>Name</small>
            <span>{{ variant.DescrizioneArticolo }}</span>
        </td>
        <td class="ODTStock">
            <small>Stock</small>
            <span class="AvailibilityIcon">&nbsp;</span>
        </td>
        <td class="ODTProductColor">
            <small>Color</small>
            <span>{{ variant.DescrizioneColore }}</span>
        </td>
        <td class="ODTProductSize">
            <small>Size</small>
            <span>{{ variant.DescrizioneTaglia }}</span>
        </td>
        <td class="ODTProductCode">
            <small>Product code</small>
            <span>{{ variant.CodiceArticolo }}</span>
        </td>
        <td class="ODTUnitPrice">
            <small>Unit price</small>                
            <Popper>
                <span class="priceCont">  {{this.formatNumber(variant.Prezzo, 2, ',', '.')}}</span>
                <template #content>
                    <template v-if="variant.PrezzoNetto != variant.PrezzoListino && variant.PrezzoNetto < variant.PrezzoListino">
                        <strong>Current Price</strong>: <span><del>{{this.simboloValuta}} {{this.formatNumber((variant.PrezzoListino), 2, ',', '.')}} </del><ins>{{this.simboloValuta}} {{this.formatNumber((variant.PrezzoNetto), 2, ',', '.')}}</ins></span>
                    </template>
                    <template v-else>                                    
                        <strong>Current Price</strong>: {{this.simboloValuta}} {{this.formatNumber(variant.PrezzoNetto, 2, ',', '.')}}
                    </template>  
                </template>
            </Popper>
        </td>
        <td class="ODTQuantity">
            <small>Quantity</small>
            <span>{{ variant.Quantita }}</span>
        </td>
        <td class="ODTSubtotal">
            <small>Subtotal</small>
            <span>{{this.simboloValuta}} {{this.formatNumber(variant.TotaleRiga, 2, ',', '.')}}</span>
        </td>
    </tr>    
</template>

<script>

import {priceMixin, valutaMixin} from "@/mixin.js";


export default {
    name: "RigaOrdineStorico",   
    props: {
        variant: Object
    },
    data(){
        return {
            showModal: false,
            responseCart: 1
        }
    },
    mixins: [priceMixin, valutaMixin]   
}
</script>
<style>
:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover:	#ffffff;
    --popper-theme-text-color:	inherit;
    --popper-theme-border-width:	1px;
    --popper-theme-border-style:	solid;
    --popper-theme-border-color:	#fce200;
    --popper-theme-border-radius:	6px;
    --popper-theme-padding:	16px;
    --popper-theme-box-shadow:	0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>