<template>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" name="example">
        <div class="imgCarrello">          
            <img src="images/Logo.png" style="width:80px;">           
        </div>
        <div class="textCarrello">
            <template>
                <div class="textSuccess">{{ this.risorse('CarrelloOk') }}</div>
            </template>
        </div>      
        <div class="btnContainer">
            <div class="Button CustomColor"><a class="btnCart btnContinue" href="javascript:void(0)" @click="this.closeModal()">{{ this.risorse('ContinuaShop') }}</a></div>
            <div class="Button CustomColor"><a class="btnCart" href="javascript:void(0)" @click="this.goToCart()">{{ this.risorse('VaiCarrello') }}</a></div>
        </div>
    </vue-final-modal>
</template>

<script>

import {risorseMixin} from "@/mixin.js";

export default { 
    name: "ModalCart",
    props:{
        //showModal: Boolean,
        response: Number
    },
    data(){
        return{
            showModal: null
        }
    },
    mixins:[risorseMixin],
    methods:{
        closeModal: function(){
            this.$vfm.hide('example') 
        },
        goToCart: function(){           
            this.$router.push({name: "ShoppingCart"});          
        }
    }
}
</script>
<style>

.modal-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.modal-content {
    display: flex !important;
    flex-direction: column !important;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 0.25rem !important;
    background: #fff !important;
    width: 800px !important;
}
.imgCarrello{
    width: 50px;
    display: inline-block;
    margin: 0 auto 0 auto;
}

.textCarrello{
    padding: 20px 0 20px 0;
    text-align: center;
}

.textCarrello .textDanger{
    color: #ff0000;
}

.textCarrello .textSuccess{
    color: #00bb2d;
}

.textCarrello .textWarning{
    color:coral;
}

.btnContainer{   
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;    
}

.btnCart{
    width: 100%;
    padding-left:0;
    padding-right:0;
    text-align: center;
}

.btnContinue{
    background: #101820 !important;
    color: #fff !important;
}
</style>